import React, { useState } from 'react';
import PopoverNav from './PopoverNav';
import { bemify } from '@ja/helpers/bemClassHelper';

import './HamburgerMenu.css';

const bemClasses = bemify('hamburger');

export const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen((prevState) => !prevState);
  const closeMenu = () => setIsOpen(false);

  return (
    <>
      <button
        onClick={toggleMenu}
        className={bemClasses('', isOpen ? 'active' : '')}>
        <h2>Site Menu</h2>
      </button>
      <PopoverNav closeMenu={closeMenu} isOpen={isOpen} />
    </>
  );
};
