import { useEffect } from 'react';
import FilterLink from '@ja/components/common/FilterLink';
import JAIcon from '@ja/components/common/JAIcon';
import { getSupportsPassive } from '@ja/helpers/supportsPassivePolyfill';

import './index.css';
import { useUIContext } from '@ja/context/UIContext';
import { styled } from '@real-system/styled-library';

const LogoComponent = ({ bemClasses }) => {
  const { isDark } = useUIContext();
  const fadeLogoTextOnScroll = () => {
    if (document.documentElement.scrollTop > 100) {
      document.getElementById('');
      document.getElementById('logo-text').setAttribute('style', 'opacity: 0;');
    } else {
      document.getElementById('logo-text').removeAttribute('style');
    }
  };

  useEffect(() => {
    const supportsPassive = getSupportsPassive();
    window.addEventListener(
      'scroll',
      fadeLogoTextOnScroll,
      supportsPassive ? { passive: true } : false
    );
    document.body.addEventListener(
      'touchmove',
      fadeLogoTextOnScroll,
      supportsPassive ? { passive: true } : false
    );
    return () => {
      window.removeEventListener('scroll', fadeLogoTextOnScroll, false);
      document.body.removeEventListener(
        'touchmove',
        fadeLogoTextOnScroll,
        false
      );
    };
  }, []);

  return (
    <FilterLink className={bemClasses('logo')} pathName="/">
      <JAIcon
        name={isDark ? 'logoV2' : 'logoV2Dark'}
        cssClasses={bemClasses('logo-img')}
      />
      <LogoText id="logo-text" isDark={isDark}>
        just appraisers
      </LogoText>
    </FilterLink>
  );
};

const LogoText = styled('span')({
  display: 'inline-flex',
  width: 'auto',
  whiteSpace: 'nowrap',
  fontWeight: '400',
  color: 'var(--primary)',
  fontFamily: 'var(--logo-font-family)',
  fontSize: '2.2rem',
  transition: 'all 0.1s ease-in-out',
  transform: 'translateY(-.4rem)',
});

export default LogoComponent;
