import React from 'react';
import FilterLink from '@ja/components/common/FilterLink';
import { bemify } from '@ja/helpers/bemClassHelper';
import history from '@ja/helpers/browserHistory';

import './PopoverNav.css';
import { useOutsideClick } from '@ja/helpers/useClickOutside';

const popoverNavClasses = bemify('popover-nav');

/** @todo fix click outside */
const PopoverNav = ({ isOpen, closeMenu }) => {
  const handleClosePopover =
    (forceClose = false) =>
    (e, clickedWithin) => {
      if (forceClose) return closeMenu();

      if (clickedWithin) {
        if (e.target.attributes['data-page']) {
          history.push(e.target.pathname);
          return closeMenu();
        }
        return;
      }
      closeMenu();
    };

  const ref = useOutsideClick({ customCallback: handleClosePopover() });

  return (
    <div ref={ref} className={popoverNavClasses()}>
      <div className={popoverNavClasses('container', isOpen ? 'active' : '')}>
        <button
          className={popoverNavClasses('close')}
          onClick={handleClosePopover(true)}>
          Close
        </button>
        <div className={popoverNavClasses('nav-links')}>
          <ul>
            <li>
              <FilterLink pathName="/" data-page>
                Home
              </FilterLink>
            </li>
            <li>
              <FilterLink
                pathName="/about-us/what-weve-done/"
                data-page
                isActive={(match, location) =>
                  location.pathname.includes('about-us')
                }>
                About Us
              </FilterLink>
            </li>
            <li>
              <FilterLink
                pathName="/appraisal-services/the-jewelry/"
                data-page
                isActive={(match, location) =>
                  location.pathname.includes('appraisal-services')
                }>
                Appraisal Services
              </FilterLink>
            </li>
            <li>
              <FilterLink pathName="/contact/" data-page>
                Contact
              </FilterLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PopoverNav;
