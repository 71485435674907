import React, { memo } from 'react';
import { connect } from 'react-redux';
import { openContact } from '@ja/redux/actions/contact';
import { disableBodyScrolling } from '@ja/helpers/preventScrolling';
import FilterLink from '@ja/components/common/FilterLink';
import { HamburgerMenu } from '../PopoverNav/HamburgerMenu';
import { bemify } from '@ja/helpers/bemClassHelper';

import './index.css';

const bemClasses = bemify('nav');
const getIsActive = (parentPath) => (_, location) => {
  console.log(parentPath, location);
  return location.pathname.includes(parentPath);
};

const Nav = ({ openContact }) => (
  <ul className={bemClasses()}>
    <li className={bemClasses('nav-items')}>
      <FilterLink pathName="/">Home</FilterLink>
      <FilterLink
        pathName="/about-us/what-weve-done/"
        isActive={getIsActive('about-us')}
        data-page>
        About Us
      </FilterLink>
      <FilterLink
        pathName="/appraisal-services/the-jewelry/"
        isActive={getIsActive('appraisal-services')}
        data-page>
        Appraisal Services
      </FilterLink>
      <FilterLink pathName="/contact/" data-page>
        Contact
      </FilterLink>
      <button className={bemClasses('lets-talk')} onClick={openContact}>
        Let's talk
      </button>
    </li>
    <li className={bemClasses('mobile-nav-ctas')}>
      <button className={bemClasses('lets-talk')} onClick={openContact}>
        Let's talk
      </button>
    </li>
    <li className={bemClasses('hamburger')}>
      <HamburgerMenu />
    </li>
  </ul>
);

const mapDispatchToProps = (dispatch) => ({
  openContact: () => {
    disableBodyScrolling();
    dispatch(openContact({ contactType: 'GetInTouch' }));
  },
});

export default connect(null, mapDispatchToProps)(Nav);
