import { useEffect } from 'react';
import { styled } from '@real-system/styled-library';

import Logo from './Logo';
import Nav from './Nav';
import history from '@ja/helpers/browserHistory';
import { bemify } from '@ja/helpers/bemClassHelper';

import './index.css';
import { tMode, useUIContext } from '@ja/context/UIContext';

const headerClasses = bemify('header');

const HeaderComponent = () => {
  const { navIsVisible, isDark } = useUIContext();
  const handleScrollToTop = (e = {}) => {
    const { target: { attributes = {} } = {} } = e;
    if (attributes['data-page'] && attributes.href) {
      window.scrollTo(0, 0);
      history.push(attributes.href.nodeValue);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleScrollToTop, false);
    return () => {
      document.removeEventListener('mousedown', handleScrollToTop, false);
    };
  }, []);

  return (
    <Wrapper isDark={isDark}>
      <div className={headerClasses('container', navIsVisible ? '' : 'center')}>
        <Logo bemClasses={headerClasses} />
        {navIsVisible && <Nav />}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ isDark }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  width: '100%',
  backgroundColor: isDark ? 'var(--faint)' : 'var(--light)',
  ...(isDark
    ? { boxShadow: '0 0 10px 0 var(--brand-light)' }
    : { boxShadow: '0 1px 5px 1px var(--shade2)' }),
  zIndex: 4,
}));

export default HeaderComponent;
