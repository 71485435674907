import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const FilterLink = ({
  className,
  pathName,
  children,
  disableActiveProps,
  ...otherProps
}) => (
  <NavLink
    to={pathName}
    exact={pathName === '/'}
    activeClassName={disableActiveProps ? '' : 'active'}
    className={className}
    {...otherProps}>
    {children}
  </NavLink>
);

FilterLink.defaultProps = {
  className: '',
  pathName: '',
  disableActiveProps: false,
};

FilterLink.propTypes = {
  className: PropTypes.string,
  pathName: PropTypes.string,
  disableActiveProps: PropTypes.bool,
};

export default FilterLink;
